/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { 
  Auth, 
  Dashboard, 
  Reports,
  DetailsReport, 
  ItemsReport,
  ItemDescription,
  CreateDescription,
  DescriptionImages,
  Inspections,
  InpectionVisits,
  DetailsInspection,
  DetailsVisit,
  VisitImages,
  FunctionalityTests,
  CreateFunctionalityTest,
  DetailsFunctionalityTest,
  NotFound,
} from "./modules"
import { Route, Routes, Navigate } from 'react-router-dom';

export default () => {
  return (
    <Routes>
       <Route path="/" element={<Navigate to="/dashboard" replace />} />
      <Route exact path='/auth' element={<Auth />} />
      <Route exact path='/dashboard' element={<Dashboard />} />
      {/* reports */}
      <Route exact path='/reports' element={<Reports />} />
      <Route exact path='/reports/create' element={<DetailsReport />} />
      <Route exact path='/reports/:id' element={<DetailsReport />} />
      <Route exact path='/items/:id/report' element={<ItemsReport />} />
      <Route exact path='/item/:report_item_id/description/create' element={<CreateDescription />} />
      <Route exact path='/descriptions/:id/item' element={<ItemDescription />} />
      <Route exact path='/description/:id/images' element={<DescriptionImages />} />
      {/* functionality tests */}
      <Route exact path='/functionality_tests/:report_id/report' element={<FunctionalityTests />} />
      {/* <Route exact path='/functionality_test/:report_id/report/create' element={<DetailsFunctionalityTest />} /> */}
      <Route exact path='/functionality_test/:report_id/report/create' element={<CreateFunctionalityTest />} />
      <Route exact path='/functionality_test/:id' element={<DetailsFunctionalityTest />} />
      {/* inspections */}
      <Route exact path='/inspections' element={<Inspections />} />
      <Route exact path='/inspections/create' element={<DetailsInspection />} />
      <Route exact path='/inspections/:id' element={<DetailsInspection />} />
      {/* visits */}
      <Route exact path='/visits/:inspection_id/inspection' element={<InpectionVisits />} />
      <Route exact path='/visits/inspection/:inspection_id/create' element={<DetailsVisit />} />
      <Route exact path='/visits/:id/edit/inspection/:inspection_id' element={<DetailsVisit />} />
      <Route exact path='/visit/:inspection_visit_id/images' element={<VisitImages />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}