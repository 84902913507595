import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
/* Common */
import { optionsRadioTestFunctionality } from "@/common/constants/index";
/* Common / Lauyouts */
import ContentHeader from "@/common/layouts/ContentHeader";
import Content from "@/common/layouts/Content";
import Row from "@/common/layouts/Row";
import Grid from "@/common/layouts/Grid";
/* Components */
import Card from "@/components/Card";
/* Reducers */
import { show as showReport } from "@/reducers/Reports/ReportsReducer";

import { useCalloutReport } from "@/pages/Reports/CalloutReport";

function Index() {
  const { report_id } = useParams();
  const dispatch = useDispatch();
  const { setReportInfo, CalloutReport } = useCalloutReport();

  useEffect(() => {
    dispatch(showReport({ id: report_id })).then(({ payload }) => {
      setReportInfo(payload);
    });
    // eslint-disable-next-line
  }, [report_id]);

  return (
    <>
      <ContentHeader
        title="Teste de Funcionalidade"
        previousRoute={`/functionality_tests/${report_id}/report`}
      />
      <Content>
        <CalloutReport hideReportItems />
        <Card
          title="Incluir Teste"
          iconTitle="plus"
        >
          <Row>
          </Row>
        </Card>
      </Content>
    </>
  );
}

export default Index;
